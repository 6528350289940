import './App.css';

function App() {
    return (
	<div className="App">
	  <header className="App-header">
	    <h1>A Beautiful Day </h1>
	  </header>
	</div>
    );
}

export default App;
